import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './auth.css';
import './dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './login/login';
import SigninOtp from './login/signinOtp';
import Options from './login/optionLogin';
import BusinessStepone from './login/businessStepone';
import BusinessSteptwo from './login/businessSteptwo';
import BusinessStepthree from './login/businessStepthree';
import BusinessStepfour from './login/businessStepfour';
import BusinessStepfive from './login/businessStepfive';
import BusinessStepsix from './login/businessStepsix';
import BusinessFinish from './login/businessFinish';
import PersonalStepone from './login/personalStepone';
import PersonalSteptwo from './login/personalSteptwo';
import PersonalStepthree from './login/personalStepthree';
import GovtStepone from './login/governmentStepOne';
import GovtSteptwo from './login/governmentSteptwo';
import GovtStepthree from './login/governmentStepthree';
import GovtStepfour from './login/governmentStepFour';
import GovtStepfive from './login/governmentStepfive';
import GovtStepsix from './login/governmentStepsix';
import ChangeEmail from './login/changeEmail';
import PersonalLogin from './login/personalSignup';
import GovtLogin from './login/govtSignup';
import BusinessLogin from './login/businessSignup';
import ForgotPassword from './login/forgotPassword';
import PasswordReset from './login/passwordReset';
import SignupOTP from './login/signupOtp';
import Home from './dashboard/home';
import Explore from './dashboard/explore';
import Integrate from './dashboard/integrate';
import Support from './dashboard/support';
import Payment from './dashboard/payment';
import Account from './dashboard/account';
import Request from './dashboard/request';
import Logout from './dashboard/logout';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>}></Route>
      <Route path="/login" element={<Login/>}></Route>
      <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
      <Route path="/reset-password" element={<PasswordReset/>}></Route>
      <Route path="/reset-password" element={<PasswordReset/>}></Route>
      <Route path="/otp-verify" element={<SignupOTP/>}></Route>
      <Route path="/otp" element={<SigninOtp/>}></Route>
      <Route path="/sign-up" element={<Options/>}></Route>
      <Route path="/business/step-one" element={<BusinessStepone/>}></Route>
      <Route path="/govt/step-one" element={<GovtStepone/>}></Route>
      <Route path="/personal/step-one" element={<PersonalStepone/>}></Route>
      <Route path="/business/step-two" element={<BusinessSteptwo/>}></Route>
      <Route path="/govt/step-two" element={<GovtSteptwo/>}></Route>
      <Route path="/personal/step-two" element={<PersonalSteptwo/>}></Route>
      <Route path="/business/step-three" element={<BusinessStepthree/>}></Route>
      <Route path="/govt/step-three" element={<GovtStepthree/>}></Route>
      <Route path="/personal/step-three" element={<PersonalStepthree/>}></Route>
      <Route path="/business/step-four" element={<BusinessStepfour/>}></Route>
      <Route path="/govt/step-four" element={<GovtStepfour/>}></Route>
      <Route path="/business/step-five" element={<BusinessStepfive/>}></Route>
      <Route path="/govt/step-five" element={<GovtStepfive/>}></Route>
      <Route path="/business/step-six" element={<BusinessStepsix/>}></Route>
      <Route path="/govt/step-six" element={<GovtStepsix/>}></Route>
      <Route path="/business/finish" element={<BusinessFinish/>}></Route>
      <Route path="/govt/finish" element={<BusinessFinish/>}></Route>
      <Route path="/personal/finish" element={<BusinessFinish/>}></Route>
      <Route path="/signup-for-personal" element={<PersonalLogin/>}></Route>
      <Route path="/signup-for-govt" element={<GovtLogin/>}></Route>
      <Route path="/signup-for-business" element={<BusinessLogin/>}></Route>
      <Route path="/change-email" element={<ChangeEmail/>}></Route>
      <Route path="/dashboard" element={<Home/>}></Route>
      <Route path="/explore" element={<Explore/>}></Route>
      <Route path="/integration" element={<Integrate/>}></Route>
      <Route path="/support" element={<Support/>}></Route>
      <Route path="/payment" element={<Payment/>}></Route>
      <Route path="/account" element={<Account/>}></Route>
      <Route path="/request" element={<Request/>}></Route>
      <Route path="/logout" element={<Logout/>}></Route>
      

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
