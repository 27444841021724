import { Form } from "react-bootstrap";
import logo from "../assets/onescudlogo.svg";
import stepone from "../assets/step-3.svg";
import {Link } from "react-router-dom";
import Select from 'react-select'

const business = [
  { value: 'Financial-management', label: 'Financial management' },
  { value: 'Payment-gateway', label: 'Payment gateway' },
  { value: 'Cryptocurrency', label: 'Cryptocurrency' }
]

const industry = [
  { value: 'E-commerce', label: 'E-commerce' },
  { value: 'Education', label: 'Education' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Food', label: 'Food' }
]

function BusinessStepthree() {
  return (
    <section className="businessbuild">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <img src={logo} alt="logo" className="img-fluid mb-5" />
            <img src={stepone} alt="stepper" className="img-fluid w-100" />
          </div>
          <div className="row mt-9">
            <div className="col-sm-6">
              <h2>Select what the application you will like to create will have</h2>
              <p className="mt-3">Select the features you need from the list below</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-5">
              <Form>
                <Form.Group className="mb-5">
                  <Form.Label>Business</Form.Label>
                  <Select
                    options={business}
                    className="basic-multi-select"
                    classNamePrefix="s"
                    isMulti
                   />
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Industries</Form.Label>
                  <Select
                    options={industry}
                    className="basic-multi-select"
                    classNamePrefix="s"
                    isMulti
                   />
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className="row mt-5 justify-content-end">
            <div className="col-sm-4">
              <div className="d-flex flex-row justify-content-end">
                <Link to="/business/step-two" className="btn btn-back">Back</Link>
                <Link to="/business/step-four" className="ms-3 btn btn-next">Next</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default BusinessStepthree