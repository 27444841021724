import { Col, Form, Row } from "react-bootstrap";
import logo from "../assets/onescudlogo.svg";
import stepone from "../assets/step-6.svg";
import {Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";
const fileTypes = ["JPG", "PNG"];


function BusinessStepsix() {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <section className="businessbuild">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <img src={logo} alt="logo" className="img-fluid mb-5" />
            <img src={stepone} alt="stepper" className="img-fluid w-100" />
          </div>
          <div className="row mt-9">
            <div className="col-sm-6">
              <div className="">
                <h2>Supporting materials. (E.g Logo,Brand manual,Brief etc )</h2>
                <p className="mt-3">Upload brand materials you will like to use. (E.g Logo/Brand manual)</p>
                <Form className="mt-5">
                  <Row className="mb-5">
                    <Col>
                      <Form.Label>Brand Color</Form.Label>
                      <Form.Control placeholder="#0000" />
                    </Col>
                    <Col>
                      <Form.Label>Brand Font</Form.Label>
                      <Form.Control placeholder="Enter Name of Font" />
                    </Col>
                  </Row>
                  <Form.Group className="">
                    <FileUploader handleChange={handleChange} name="myFile" types={fileTypes} />
                    <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          
          <div className="row mt-9 justify-content-end">
            <div className="col-sm-4">
              <div className="d-flex flex-row justify-content-end">
                <Link to="/" className="btn btn-back bg-white">Skip</Link>
                <Link to="/business/step-five" className="mx-3 btn btn-back">Back</Link>
                <Link to="/business/finish" className=" btn btn-next">Next</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default BusinessStepsix