import LoginBusiness from "../components/auths/loginBusiness"

function BusinessLogin() {
  return (
    <div>
      <LoginBusiness/>
    </div>
  )
}

export default BusinessLogin