import Notification from "../../parts/notification"
import line from "../../assets/line.svg"
import linee from "../../assets/20.svg"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"


function DashboardRequest() {
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="mt-5 request-b px-5">
        <div className="row">
          <div className="col-sm-8">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Active Request</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Pending Request</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Cancelled Request</button>
              </li>
            </ul>
          </div>
          <div className="col-sm-4">
            <button type="button" className="btn btn-next float-end"><i className="bi bi-plus me-3"></i>Create Request</button>
          </div>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
            <div className="card-pro mt-4 justify-content-between mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Healthcare Product</h3>
                  <p className="my-3">We are cooking something really good for you. You will be<br/>contacted for the next steps</p>
                  <button className="int-btn btn btn-primary px-3" data-bs-toggle="modal" data-bs-target="#viewdetail">View Details</button>
                </div>
                <div className="col-sm-6 mt-5">
                  <Link><img src={line} alt="line" className="img-fluid w-100" /></Link>
                </div>
              </div>
            </div>
            <div className="card-pro mt-4 justify-content-between mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Healthcare Product</h3>
                  <p className="my-3">We are cooking something really good for you. You will be<br/>contacted for the next steps</p>
                  <button className="int-btn btn btn-primary px-3" data-bs-toggle="modal" data-bs-target="#viewdetail">View Details</button>
                </div>
                <div className="col-sm-6 mt-5">
                  <Link><img src={line} alt="line" className="img-fluid w-100" /></Link>
                </div>
              </div>
            </div>
            <div className="card-pro mt-4 justify-content-between mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Healthcare Product</h3>
                  <p className="my-3">We are cooking something really good for you. You will be<br/>contacted for the next steps</p>
                  <button className="int-btn btn btn-primary px-3" data-bs-toggle="modal" data-bs-target="#viewdetail">View Details</button>
                </div>
                <div className="col-sm-6 mt-5">
                  <Link><img src={line} alt="line" className="img-fluid w-100" /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
            <div className="mt-4 ">
              <div className="row">
                <div className="col-sm-6 pending-r">
                  <div className="row">
                    <div className="col-sm-4 bor-l">
                      <h2>Health Product</h2>
                    </div>
                    <div className="col-sm-4 bor-l">
                      <div className="d-flex flex-row">
                        <div><span className="req"><Icon icon="ic:baseline-timeline" /></span></div>
                        <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                      </div>
                      <div className="float-end" style={{marginTop: "-30px"}}>
                        <h3 className="">January 9, 2023</h3>
                        <h3 className="">January 9, 2023</h3>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex flex-row">
                        <div><span className="req"><Icon icon="cil:industry" /></span></div>
                        <div className="ms-3 mt-1"><h6>Industry</h6></div>
                      </div>
                      <h3 className="float-end" style={{marginTop: "-26px"}}>Healthcare</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 pending-r">
                  <div className="row">
                    <div className="col-sm-4 bor-l">
                      <h2>Health Product</h2>
                    </div>
                    <div className="col-sm-4 bor-l">
                      <div className="d-flex flex-row">
                        <div><span className="req"><Icon icon="ic:baseline-timeline" /></span></div>
                        <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                      </div>
                      <div className="float-end" style={{marginTop: "-30px"}}>
                        <h3 className="">January 9, 2023</h3>
                        <h3 className="">January 9, 2023</h3>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex flex-row">
                        <div><span className="req"><Icon icon="cil:industry" /></span></div>
                        <div className="ms-3 mt-1"><h6>Industry</h6></div>
                      </div>
                      <h3 className="float-end" style={{marginTop: "-26px"}}>Healthcare</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 pending-r">
                  <div className="row">
                    <div className="col-sm-4 bor-l">
                      <h2>Health Product</h2>
                    </div>
                    <div className="col-sm-4 bor-l">
                      <div className="d-flex flex-row">
                        <div><span className="req"><Icon icon="ic:baseline-timeline" /></span></div>
                        <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                      </div>
                      <div className="float-end" style={{marginTop: "-30px"}}>
                        <h3 className="">January 9, 2023</h3>
                        <h3 className="">January 9, 2023</h3>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex flex-row">
                        <div><span className="req"><Icon icon="cil:industry" /></span></div>
                        <div className="ms-3 mt-1"><h6>Industry</h6></div>
                      </div>
                      <h3 className="float-end" style={{marginTop: "-26px"}}>Healthcare</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
            <div className="mt-4 ">
              <div className="row">
                <div className="col-sm-6 pending-r">
                  <div className="row">
                    <div className="col-sm-4 bor-3">
                      <h2 style={{color: "#EF4444"}}>Health Product</h2>
                    </div>
                    <div className="col-sm-4 bor-3">
                      <div className="d-flex flex-row">
                        <div><span className="req" style={{color: "#EF4444"}}><Icon icon="ic:baseline-timeline" /></span></div>
                        <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                      </div>
                      <div className="float-end" style={{marginTop: "-30px"}}>
                        <h3 className="">January 9, 2023</h3>
                        <h3 className="">January 9, 2023</h3>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex flex-row">
                        <div><span className="req" style={{color: "#EF4444"}}><Icon icon="cil:industry" /></span></div>
                        <div className="ms-3 mt-1"><h6>Industry</h6></div>
                      </div>
                      <h3 className="float-end" style={{marginTop: "-26px"}}>Healthcare</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 pending-r">
                  <div className="row">
                    <div className="col-sm-4 bor-3">
                      <h2 style={{color: "#EF4444"}}>Health Product</h2>
                    </div>
                    <div className="col-sm-4 bor-3">
                      <div className="d-flex flex-row">
                        <div><span className="req" style={{color: "#EF4444"}}><Icon icon="ic:baseline-timeline" /></span></div>
                        <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                      </div>
                      <div className="float-end" style={{marginTop: "-30px"}}>
                        <h3 className="">January 9, 2023</h3>
                        <h3 className="">January 9, 2023</h3>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex flex-row">
                        <div><span className="req" style={{color: "#EF4444"}}><Icon icon="cil:industry" /></span></div>
                        <div className="ms-3 mt-1"><h6>Industry</h6></div>
                      </div>
                      <h3 className="float-end" style={{marginTop: "-26px"}}>Healthcare</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="modal fade" id="viewdetail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mb-3">
              <div className="card-reqq">
                <div className="row">
                  <div className="col-sm-4 bor-l">
                    <h2>Healthcare</h2>
                  </div>
                  <div className="col-sm-4 bor-l">
                    <div className="d-flex flex-row">
                      <div><span className="req"><Icon icon="ic:baseline-timeline" /></span></div>
                      <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                    </div>
                    <div className="float-end" style={{marginTop: "-30px"}}>
                      <h3 className="">January 9, 2023</h3>
                      <h3 className="">January 9, 2023</h3>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="d-flex flex-row">
                      <div><span className="req"><Icon icon="cil:industry" /></span></div>
                      <div className="ms-3 mt-1"><h6>Industry</h6></div>
                    </div>
                    <h3 className="float-end" style={{marginTop: "-21px"}}>Healthcare</h3>
                  </div>
                </div>
                <div className="row" style={{marginTop: "100px"}}>
                  <div className="col-sm-4 mb-3">
                    <div className="d-flex flex-row">
                      <div><span className="req"><Icon icon="fe:check" className="fs-6" /></span></div>
                      <div className="ms-3 mt-1"><h6>Request submitted</h6></div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <img src={linee} alt="" className="img-fluid w-100 float-end mt-2" />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <p>We are cooking something really good for you. You will be contacted for the next steps</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardRequest