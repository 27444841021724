import { Form } from "react-bootstrap";
import logo from "../assets/onescudlogo.svg";
import stepone from "../assets/step-5.svg";
import {Link } from "react-router-dom";

function BusinessStepfive() {
  return (
    <section className="businessbuild">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <img src={logo} alt="logo" className="img-fluid mb-5" />
            <img src={stepone} alt="stepper" className="img-fluid w-100" />
          </div>
          <div className="row mt-9">
            <div className="col-sm-6">
              <div className="w-100">
                <h2>Who will use this application?</h2>
                <p className="mt-3">Let’s get you started</p>
                <Form className="mt-5">
                  <Form.Group className="mb-5">
                    <div className="mt-3">
                      <div className="form-check mb-4">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                        <label className="form-check-label" for="inlineCheckbox0">My business</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                        <label className="form-check-label" for="inlineCheckbox0">My customers</label>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          
          <div className="row topping justify-content-end">
            <div className="col-sm-4">
              <div className="d-flex flex-row justify-content-end">
                <Link to="/business/step-four" className="btn btn-back">Back</Link>
                <Link to="/business/step-six" className="ms-3 btn btn-next">Next</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default BusinessStepfive