import logo from "../../assets/scud.png"
import help from "../../assets/support.svg"
import line from "../../assets/20.svg"
import linee from "../../assets/req2.svg"
import noact from "../../assets/no-act.svg"
import logout from "../../assets/logout.svg"
import { Icon } from "@iconify/react"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay, Pagination } from "swiper";
import Notification from "../../parts/notification"
import { Link } from "react-router-dom"


function DashboardLogout() {
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="row mt-5 mx-5">
        <Swiper
          slidesPerView={2}
          spaceBetween={30}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="card-req">
              <div className="row">
                <div className="col-sm-4 bor-l">
                  <h2>Request 001</h2>
                </div>
                <div className="col-sm-4 bor-l">
                  <div className="d-flex flex-row">
                    <div><span className="req"><Icon icon="ic:baseline-timeline" /></span></div>
                    <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                  </div>
                  <div className="float-end" style={{marginTop: "-30px"}}>
                    <h3 className="">January 9, 2023</h3>
                    <h3 className="">January 9, 2023</h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="d-flex flex-row">
                    <div><span className="req"><Icon icon="cil:industry" /></span></div>
                    <div className="ms-3 mt-1"><h6>Industry</h6></div>
                  </div>
                  <h3 className="float-end" style={{marginTop: "-21px"}}>Healthcare</h3>
                </div>
              </div>
              <div className="row" style={{marginTop: "100px"}}>
                <div className="col-sm-4 mb-3">
                  <div className="d-flex flex-row">
                    <div><span className="req"><Icon icon="fe:check" className="fs-6" /></span></div>
                    <div className="ms-3 mt-1"><h6>Request submitted</h6></div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <img src={line} alt="" className="img-fluid w-100 float-end mt-2" />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <p>We are cooking something really good for you. You will be contacted for the next steps</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card-req zero-two">
              <div className="row">
                <div className="col-sm-4 bor-l">
                  <h2>Request 002</h2>
                </div>
                <div className="col-sm-4 bor-l">
                  <div className="d-flex flex-row">
                    <div><span className="req2"><Icon icon="ic:baseline-timeline" /></span></div>
                    <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                  </div>
                  <div className="float-end" style={{marginTop: "-30px"}}>
                    <h3 className="">January 9, 2023</h3>
                    <h3 className="">January 9, 2023</h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="d-flex flex-row">
                    <div><span className="req2"><Icon icon="cil:industry" /></span></div>
                    <div className="ms-3 mt-1"><h6>Industry</h6></div>
                  </div>
                  <h3 className="float-end" style={{marginTop: "-21px"}}>Healthcare</h3>
                </div>
              </div>
              <div className="row" style={{marginTop: "100px"}}>
                <div className="col-sm-4 mb-3">
                  <div className="d-flex flex-row">
                    <div><span className="req2"><Icon icon="fe:check" className="fs-6" /></span></div>
                    <div className="ms-3 mt-1"><h6>Request submitted</h6></div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <img src={linee} alt="" className="img-fluid w-100 float-end mt-2" />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <p>We are cooking something really good for you. You will be contacted for the next steps</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card-req">
              <div className="row">
                <div className="col-sm-4 bor-l">
                  <h2>Request 001</h2>
                </div>
                <div className="col-sm-4 bor-l">
                  <div className="d-flex flex-row">
                    <div><span className="req"><Icon icon="ic:baseline-timeline" /></span></div>
                    <div className="ms-3 mt-1"><h6>Timeline</h6></div>
                  </div>
                  <div className="float-end" style={{marginTop: "-30px"}}>
                    <h3 className="">January 9, 2023</h3>
                    <h3 className="">January 9, 2023</h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="d-flex flex-row">
                    <div><span className="req"><Icon icon="cil:industry" /></span></div>
                    <div className="ms-3 mt-1"><h6>Industry</h6></div>
                  </div>
                  <h3 className="float-end" style={{marginTop: "-21px"}}>Healthcare</h3>
                </div>
              </div>
              <div className="row" style={{marginTop: "100px"}}>
                <div className="col-sm-4 mb-3">
                  <div className="d-flex flex-row">
                    <div><span className="req"><Icon icon="fe:check" className="fs-6" /></span></div>
                    <div className="ms-3 mt-1"><h6>Request submitted</h6></div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <img src={line} alt="" className="img-fluid w-100 float-end mt-2" />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <p>We are cooking something really good for you. You will be contacted for the next steps</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="mt-5 mx-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="no-act pt-4">
              <h3>Activities</h3>
              <div className="no-act text-center">
                <img src={noact} alt="" className="img-fluid" title="No Recent Activity" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 mb-5">
        <div className="row justify-content-between">
          <div className="col-sm-6 mb-3">
            <div className="expl">
              <img src={logo} alt="logo" className="img-fluid" />
              <h6 className="w-50">Explore all the possibility you can have with Onescud</h6>
              <p className="mt-5 text-white">Explore Now <Icon icon="formkit:arrowright" className="ms-3" /></p>
            </div>
          </div>
          <div className="col-sm-6 mb-3">
            <div className="expl help">
              <img src={help} alt="logo" className="img-fluid mb-3" />
              <h6 className="w-50">Need Help?</h6>
              <p className="text-white float-end" style={{marginTop: "94px"}}>Get in touch <Icon icon="formkit:arrowright" className="ms-3" /></p>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="modal fade" id="logout">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mb-5">
              <div className="row justify-content-center logout">
                <div className="col-sm-8 text-center">
                  <img src={logout} alt="fist" className="img-fluid mb-4" />
                  <h6>Log Out</h6>
                  <p>Are you sure you want to logout?</p>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <Link to="" className="btn btn-out">Yes, Log out</Link>
                    <Link to="" className="ms-3 btn btn-back" data-bs-dismiss="modal">Cancel</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLogout