import { FloatingLabel } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import logo from "../../assets/onex.svg";


function FPassword() {
  return (
    <div className="d-lg-flex half">
      <div className="bg order-1 order-md-1 new-aside">
        <div className='container'>
          <div className='row align-items-start'>
            <div className='col-sm-3 p-4'>
              <Link to="/"><img src={logo} alt="logo" className='img-fluid w-100' /></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="contents order-2 order-md-2">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-md-7">
              <div style={{marginTop: "108px"}}>
                <div className="login-form">
                  <h2 className="mb-2">Forgot password?</h2>
                  <h4 className="">Enter your email to reset your password</h4>
                  <Form className="mt-4">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email address"
                      className="mb-4"
                    >
                      <Form.Control type="email" placeholder="name@example.com" />
                    </FloatingLabel>
                    <Link to="/reset-password" type="button" className="btn btn-primary w-100 login-btn">Reset Password</Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FPassword