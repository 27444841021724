import DashboardExplore from "../components/dashboardParts/dashboardExplore"
import Sidebar from "../components/dashboardParts/sidebar"

function Explore() {
  return (
    <div>
      <Sidebar/>
      <DashboardExplore/>
    </div>
  )
}

export default Explore