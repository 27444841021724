import EmailChange from "../components/auths/emailChange"

function ChangeEmail() {
  return (
    <div>
      <EmailChange/>
    </div>
  )
}

export default ChangeEmail