import DashboardAccount from "../components/dashboardParts/dashboardAccount"
import Sidebar from "../components/dashboardParts/sidebar"

function Account() {
  return (
    <div>
      <Sidebar/>
      <DashboardAccount/>
    </div>
  )
}

export default Account