import { Link } from "react-router-dom";
import nots from "../assets/nots.svg"
import market from "../assets/market.svg"
import props from "../assets/alert.svg"
import store from "../assets/store.svg"
import profile from "../assets/profile.svg"


export default function Notification() {
  return (
    <>
      <div className="d-flex flex-row">
        <div className="me-3 mt-4">
          <img src={store} alt="" className="img-fluid" />
        </div>
        <div className="dropdown noafter mt-3">
          <Link className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ><img src={props} alt="props" className="img-fluid" /></Link>
          <ul class="dropdown-menu nots-menu">
            <div className="row pt-4 px-4">
              <h6>Notifications</h6>
            </div>
            <hr />
            <div className="row p-4">
              <div className="col">
                <h3>Today</h3>
                <div>
                  <div className="d-flex flex-row">
                    <img src={nots} alt="nots" className="img-fluid me-2" />
                    <p className="mt-3">You have a message from support</p>
                  </div>
                  <span className="float-end" style={{marginTop: "-23px"}}>11:35 AM</span>
                  <hr />
                </div>
                <div>
                  <div className="d-flex flex-row">
                    <img src={market} alt="nots" className="img-fluid me-2" />
                    <p className="mt-3">View the market place for the latest app<br/>version on the app store</p>
                  </div>
                  <span className="float-end" style={{marginTop: "-29px"}}>11:35 AM</span>
                  <hr />
                </div>
                <h3 className="mt-5">Older</h3>
                <div>
                  <div className="d-flex flex-row">
                    <img src={nots} alt="nots" className="img-fluid me-2" />
                    <p className="mt-3">You have a message from support</p>
                  </div>
                  <span className="float-end" style={{marginTop: "-23px"}}>11:35 AM</span>
                  <hr />
                </div>
                <div>
                  <div className="d-flex flex-row">
                    <img src={market} alt="nots" className="img-fluid me-2" />
                    <p className="mt-3">View the market place for the latest app<br/>version on the app store</p>
                  </div>
                  <span className="float-end" style={{marginTop: "-29px"}}>11:35 AM</span>
                  <hr />
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div className="ms-3 mb-3">
          <Link to="/account"><img src={profile} alt="" className="img-fluid" /></Link>
        </div>
      </div>
    </>
    
  )
}