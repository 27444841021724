import { Link } from "react-router-dom"
import spaycemoon from "../../assets/spaycemoon.svg"
import fist from "../../assets/fist.png"
import pass from "../../assets/pass.png"
import hargon from "../../assets/hargon.svg"
import ardilla from "../../assets/ardilla.svg"
import vernde from "../../assets/vernde.svg"
import pomelo from "../../assets/pomelo.svg"
import curved from "../../assets/curved.svg"
import nearly from "../../assets/nearly.svg"
import onwa from "../../assets/onwa.svg"
import zettarh from "../../assets/zettarh.svg"
import keed from "../../assets/keed.svg"
import onescud from "../../assets/onescud.svg"
import { Button } from "react-bootstrap"
import Notification from "../../parts/notification"




function DashboardIntegrate() {
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 integration">
        <div className="row justify-content-center">
          <div className="col-sm-8 text-center">
            <h2><span style={{color: "#00C9C9"}}>Create</span> & <span style={{color: "#00C9C9"}}>power</span> your business<br/>with Onescud</h2>
            <h6 className="mt-3">Integrate powerful tools and products that aid your business growth.</h6>
          </div>
        </div>
        <div className="products-list">
          <div className="row justify-content-center mb-4">
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={spaycemoon} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn" data-bs-toggle="modal" data-bs-target="#addrequest">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={ardilla} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn" data-bs-toggle="modal" data-bs-target="#addrequest">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={hargon} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn" data-bs-toggle="modal" data-bs-target="#addrequest">Add Request</Button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={vernde} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn" data-bs-toggle="modal" data-bs-target="#addrequest">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={zettarh} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={pomelo} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={onwa} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={keed} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={onescud} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={curved} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
              <div className="p-list">
                <img src={nearly} alt="spaycemoon" className="img-fluid mb-4" />
                <p>Integrate powerful tools and products that aid your business growth.</p>
                <Button className="int-btn">Add Request</Button>
              </div>
            </div>
            <div className="col-sm-3 mb-3">
            </div>
          </div>
        </div>

        {/* Modal for Request */}
        <div className="modal fade" id="addrequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mb-5">
                <div className="row justify-content-center">
                  <div className="col-sm-8 text-center">
                    <img src={fist} alt="fist" className="img-fluid mb-4" />
                    <h6>I agree that this integration should be added to my request</h6>
                    <div className="d-flex flex-row justify-content-center mt-4">
                      <Link to="" className="btn btn-next" data-bs-toggle="modal" data-bs-target="#success">Agree</Link>
                      <Link to="" className="ms-3 btn btn-back">Cancel</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="success" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mb-5">
                <div className="row justify-content-center">
                  <div className="col-sm-8 text-center">
                    <img src={pass} alt="fist" className="img-fluid mb-4" />
                    <h6>Request sent successfully</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default DashboardIntegrate