import FPassword from "../components/auths/fPassword"

function ForgotPassword() {
  return (
    <div>
      <FPassword/>
    </div>
  )
}

export default ForgotPassword