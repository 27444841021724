import { Link } from 'react-router-dom';
import logo from "../../assets/onex.svg";
import OtpInput from "react18-input-otp";
import { useState } from "react";


function LoginOtp() {
  const [otp, setOtp] = useState('');
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };
  return (
    <div className="d-lg-flex half">
      <div className="bg order-1 order-md-1 new-aside">
        <div className='container'>
          <div className='row align-items-start'>
            <div className='col-sm-3 p-4'>
              <Link to="/"><img src={logo} alt="logo" className='img-fluid w-100' /></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="contents order-2 order-md-2">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-md-7">
              <div style={{marginTop: "108px"}}>
                <div className="login-form">
                  <h2 className="mb-2">OTP Code Verification</h2>
                  <h4 className="">We sent you an OTP code to this email <span className="text-black fw-bold">davidoshodi656@gmail.com</span> </h4>
                  <div className="my-5">
                    <OtpInput value={otp} onChange={handleChange} numInputs={6} inputStyle="form-control otp-form" />
                  </div>
                  <h4 className="mb-2">Didn’t get an OTP code? <span className="resend">RESEND</span></h4>
                  <Link to="/dashboard" type="button" className="btn btn-primary w-100 login-btn">Continue</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginOtp