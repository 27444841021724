import logo from "../assets/onescudlogo.svg";
import stepone from "../assets/step-1.svg";
import explore from "../assets/explore.svg";
import cog from "../assets/cog.svg";
import { Link } from "react-router-dom";


function PersonalStepone() {
  return (
    <section className="businessbuild">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <img src={logo} alt="logo" className="img-fluid mb-5" />
            <img src={stepone} alt="stepper" className="img-fluid w-100" />
          </div>
          <div className="row mt-9">
            <div className="col-sm-6">
              <h2>Hi there! What you want to do?</h2>
              <p className="mt-3">Let’s get you started</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-3 mb-3">
              <Link role="button" data-bs-toggle="button">
                <div className="build-card">
                  <img src={cog} alt="explore" className="img-fluid" />
                  <h3 className="my-3">BUILD</h3>
                  <p>Stackivy for start-ups ensures you have a reliable partner to help shoulder</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-3 mb-3">
              <Link role="button" data-bs-toggle="button">
                <div className="build-card">
                  <img src={explore} alt="explore" className="img-fluid" />
                  <h3 className="my-3">EXPLORE</h3>
                  <p>Welcome to the world of un-limitless possibilities. Explore our various solutions and choose the right one for you.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row mt-9 justify-content-end">
            <div className="col-sm-4">
              <div className="d-flex flex-row justify-content-end">
                <Link to="/personal/step-one" className="btn btn-back">Back</Link>
                <Link to="/personal/step-two" className="ms-3 btn btn-next">Next</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default PersonalStepone