import { Form } from "react-bootstrap";
import logo from "../assets/onescudlogo.svg";
import stepone from "../assets/step-4.svg";
import {Link } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

function GovtStepfour() {
  return (
    <section className="businessbuild">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <img src={logo} alt="logo" className="img-fluid mb-5" />
            <img src={stepone} alt="stepper" className="img-fluid w-100" />
          </div>
          <div className="row mt-9">
            <div className="col-sm-6">
              <div className="w-50">
                <h2>Choose a Budget</h2>
                <p className="mt-3">Select from the list of features you need</p>
                <Form className="mt-4">
                  <Form.Group className="mb-5">
                    <CurrencyInput
                      className="form-control"
                      prefix="₦"
                      placeholder="Enter Budget Amount (₦)"
                      defaultValue={10000}
                      decimalsLimit={2}
                      onValueChange={(value, name) => console.log(value, name)}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className="mt-5">
                <h2>Describe what you want to build (Your limit is your imagination)</h2>
                <p className="mt-3">Let’s get you started</p>
                <Form className="mt-4">
                  <Form.Group className="mb-5">
                    <Form.Control as="textarea" rows={5} />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          
          <div className="row mt-9 justify-content-end">
            <div className="col-sm-4">
              <div className="d-flex flex-row justify-content-end">
                <Link to="/govt/step-three" className="btn btn-back">Back</Link>
                <Link to="/govt/step-five" className="ms-3 btn btn-next">Next</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default GovtStepfour