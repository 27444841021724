import DashboardHome from "../components/dashboardParts/dashboardHome"
import Sidebar from "../components/dashboardParts/sidebar"

function Home() {
  return (
    <div>
      <Sidebar/>
      <DashboardHome/>
    </div>
  )
}

export default Home