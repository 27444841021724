import DashboardRequest from "../components/dashboardParts/dashboardRequest"
import Sidebar from "../components/dashboardParts/sidebar"

function Request() {
  return (
    <div>
      <Sidebar/>
      <DashboardRequest/>
    </div>
  )
}

export default Request