import DashboardSupport from "../components/dashboardParts/dashboardSupport"
import Sidebar from "../components/dashboardParts/sidebar"

function Support() {
  return (
    <div>
      <Sidebar/>
      <DashboardSupport/>
    </div>
  )
}

export default Support