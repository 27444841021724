import DashboardLogout from "../components/dashboardParts/dashboardLogout"
import Sidebar from "../components/dashboardParts/sidebar"

function Logout() {
  return (
    <div>
      <Sidebar/>
      <DashboardLogout/>
    </div>
  )
}

export default Logout