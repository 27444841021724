import RPassword from "../components/auths/resetPassword"

function PasswordReset() {
  return (
    <div>
      <RPassword/>
    </div>
  )
}

export default PasswordReset