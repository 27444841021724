import logo from "../assets/onescudlogo.svg";
import success from "../assets/clarify.svg";


function BusinessFinish() {
  return (
    <section className="businessbuild">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <img src={logo} alt="logo" className="img-fluid mb-5" />
          </div>
          <div className="row mt-9 justify-content-center">
            <div className="col-sm-6 text-center">
              <img src={success} alt="logo" className="img-fluid mb-5" />
              <h2>Request submitted</h2>
              <p className="mb-5 mt-4">We are working on something really good for you. You will be contacted for the next steps</p>
              <a href="https://onescud.stackivy.africa" className=" btn btn-next py-3 px-5">Go To Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default BusinessFinish