import DashboardPayment from "../components/dashboardParts/dashboardPayment"
import Sidebar from "../components/dashboardParts/sidebar"

function Payment() {
  return (
    <div>
      <Sidebar/>
      <DashboardPayment/>
    </div>
  )
}

export default Payment