import { Link } from "react-router-dom"
import st from "../../assets/st.svg"
import active from "../../assets/active.svg"
import send from "../../assets/send.svg"
import text from "../../assets/text.svg"
import { Icon } from "@iconify/react"
import Notification from "../../parts/notification"




function DashboardSupport() {
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="support">
        <div className="row justify-content-center">
          <div className="col-sm-7">
            <div className="support-card">
              <div style={{padding: "0px 40px"}}>
                <div className="d-flex flex-row">
                  <img src={st} alt="supportteam" className="img-fluid me-2" />
                  <img src={active} alt="active" className="img-fluid" />
                </div>
                <Icon icon="material-symbols:call" className="text-black float-end" style={{marginTop: "-20px"}} />
                <hr />
              </div>
              <div className="row mt-5">
                <div className="col text-center">
                  <span className="today">Today</span>
                </div>
              </div>
              <div className="msg mt-5">
                <div className="row">
                  <div className="col">
                    <div className="d-flex">
                      <img src={text} alt="text" className="img-fluid me-3 mt-2"/>
                      <div>
                        <p>11:35 AM</p>
                        <span className="today">Hello there! how are you</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end mt-4">
                  <div className="col-sm-6">
                    <div className="d-flex justify-content-end">
                      <div>
                        <p className="text-end">11:35 AM</p>
                        <span className="today-r">Can i get a link or document about other services</span>
                      </div>
                      <img src={text} alt="text" className="img-fluid ms-3 mt-2"/>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="d-flex">
                      <img src={text} alt="text" className="img-fluid me-3 mt-2"/>
                      <div>
                        <p>11:35 AM</p>
                        <span className="today">Hi, how's you doing ?</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="row">
                  <div className="col-sm-1 text-center">
                    <Link><Icon icon="iconoir:attachment" className="text-black mt-2" /></Link>
                  </div>
                  <div className="col-sm-10">
                    <div>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" placeholder="Type message here"></textarea>
                    </div>
                  </div>
                  <div className="col-sm-1">
                    <Link><img src={send} alt="send" className="img-fluid mt-2" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardSupport