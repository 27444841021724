import { FloatingLabel } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import logo from "../../assets/onex.svg";


function SignIn() {
  return (
    <div className="d-lg-flex half">
      <div className="bg order-1 order-md-1 new-aside">
        <div className='container'>
          <div className='row align-items-start'>
            <div className='col-sm-3 p-4'>
              <Link to="/"><img src={logo} alt="logo" className='img-fluid w-100' /></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="contents order-2 order-md-2">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-md-7">
              <div style={{marginTop: "108px"}}>
                <div className="login-form">
                  <h3 className="">Sign In to access your account</h3>
                  <Form className="mt-4">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="<kode Hex/> or Email address"
                      className="mb-4"
                    >
                      <Form.Control type="email" placeholder="name@example.com" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Password" className="mb-4">
                      <Form.Control type="password" placeholder="Password" />
                    </FloatingLabel>
                    <div className="mb-4 forgot-p">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" for="flexCheckDefault">
                          Remember Password
                        </label>
                      </div>
                      <Link to="/forgot-password"><h6 className="float-end" style={{marginTop: "-21px"}}>Forgot Password?</h6></Link>
                    </div>
                    <Link to="/otp" type="button" className="btn btn-primary w-100 login-btn">Sign In</Link>
                    <div className="text-center mt-4">
                      <p>Don’t have an account?  <Link to="/sign-up" className="fw-bold" style={{color: "#00B2B2"}}>Sign Up</Link></p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn