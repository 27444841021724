import OTPVerify from "../components/auths/otpVerify"

function SignupOTP() {
  return (
    <div>
      <OTPVerify/>
    </div>
  )
}

export default SignupOTP