import Notification from "../../parts/notification"
import avatar from "../../assets/avatar.svg"
import names from "../../assets/name.svg"
import { Button, Collapse, Form } from "react-bootstrap"
import { useState } from "react";
import { Icon } from "@iconify/react";





function DashboardAccount() {
  const [open, setOpen] = useState(false);
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="accounts mt-5 px-5">
        <div className="row">
          <div className="col">
            <h2>Account</h2>
            <div className="d-flex flx-row mt-4">
              <img src={avatar} alt="avatar" className="img-fluid" />
              <div className="ms-3 avatar mt-2">
                <h6 className="mb-2">John Doe</h6>
                <p>Update your information and manage your account settings.</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row justify-content-between" style={{marginTop: "100px"}}>
          <div className="col-sm-4 mb-3">
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="John Doe" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Gender</Form.Label>
                <Form.Select aria-label="Gender">
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Trans</option>
                </Form.Select>
              </Form.Group>
              <button className="btn btn-next mt-4">Save Changes</button>
            </Form>
          </div>
          <div className="col-sm-6" style={{marginTop: "-70px"}}>
            <Button
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="btn btn-psd"
            >
              Change Password <i className="bi bi-chevron-down ms-2"></i>
            </Button>
            <Collapse in={open}>
              <div className="w-75 mt-5">
                <Form>
                  <Form.Group className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" placeholder="John Doe" />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control type="password" placeholder="name@example.com" />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="name@example.com" />
                  </Form.Group>
                </Form>
              </div>
            </Collapse>
          </div>
        </div>
        <hr />
        <div className="tablets mt-5">
          <div className="row">
            <div className="col">
              <h2>Manage Users</h2>
              <button type="button" data-bs-toggle="modal" data-bs-target="#adduser" className="btn btn-next float-end" style={{marginTop: "-30px"}}><i className="bi bi-plus me-3"></i> Add New User</button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <div className="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">NAME</th>
                      <th scope="col">ROLE</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><img src={names} alt="use" className="img-fluid" /></td>
                      <td>Viewer</td>
                      <td>johndoe@gmail.com</td>
                      <td><Icon icon="octicon:trash-24" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div class="modal fade" id="adduser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog add-user">
          <div class="modal-content add-user-content p-4">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Add New Member</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Form className="mt-5">
                <Form.Group className="mb-4">
                  <Form.Control type="text" placeholder="First Name" />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control type="text" placeholder="Last Name" />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control type="text" placeholder="Username" />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control type="tel" placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Select aria-label="Role">
                    <option value="1">User Role</option>
                    <option value="2">Admin</option>
                    <option value="3">Viewers</option>
                    <option value="4">Commentators</option>
                  </Form.Select>
                </Form.Group>
                <div className="p-3 generate">
                  <div className="d-flex flex-row">
                    <p className="">W5gt67QDf4</p>
                    <span className="gp">Generate Password</span>
                  </div>
                </div>
                <button className="btn btn-next mt-5 w-100 py-4 rounded-pill ">Submit</button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardAccount