import LoginOptions from "../components/auths/loginOptions"

function Options() {
  return (
    <div>
      <LoginOptions/>
    </div>
  )
}

export default Options