import LoginOtp from "../components/auths/loginOtp"

function SigninOtp() {
  return (
    <div>
      <LoginOtp/>
    </div>
  )
}

export default SigninOtp