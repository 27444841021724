import DashboardIntegrate from "../components/dashboardParts/dashboardIntegrate"
import Sidebar from "../components/dashboardParts/sidebar"

function Integrate() {
  return (
    <div>
      <Sidebar/>
      <DashboardIntegrate/>
    </div>
  )
}

export default Integrate