import { Link } from "react-router-dom"
import card from "../../assets/card.svg"
import mc from "../../assets/mc.svg"
import dump from "../../assets/dump.svg"
import history from "../../assets/history.svg"
import { Accordion } from "react-bootstrap"
import Notification from "../../parts/notification"





function DashboardPayment () {
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="mt-5 row mx-5 justify-content-center payments">
        <div className="col-sm-6 mb-3">
          <div className="pay-card h-pay" >
            <div className="mx-4">
              <h3>Amount Due</h3>
              <p>Integrate powerful tools and products that aid your business growth.</p>
            </div>
            <div className="mx-4 my-5">
              <h3>₦200,000.00</h3>
              <p>Due:  05/04/2023  at  7:00 PM</p>
              <div className="d-flex flex-row justify-content-end" style={{marginTop: "-52px"}}>
                <Link to="" className="btn btn-next" data-bs-toggle="modal" data-bs-target="#paynow">Pay Now</Link>
                <Link to="" className="ms-3 btn btn-back">Download Invoice</Link>
              </div>
            </div>
            <hr />
            <div className="mx-4 mt-5">
              <h3>Payment Method</h3>
              <Link to="" className="ms-3 btn btn-pay float-end" data-bs-toggle="modal" data-bs-target="#addcard" style={{marginTop: "-39px"}}>Add Card</Link>
            </div>
            <div className="mx-4 mt-5">
              <Link><img src={card} alt="card" className="img-fluid" /></Link>
              <Link><img src={dump} alt="card" className="img-fluid float-end" /></Link>
            </div>
          </div>
          <div className="mt-3">
            <h6>Need help? <span style={{color: "#00C9C9"}}>Contact Support</span></h6>
          </div>
        </div>
        <div className="col-sm-4 mb-3">
          <div className="pay-card px-4">
            <h3>Payment History</h3>
            <div className="mt-4">
              <div className="d-flex flex-row">
                <img src={history} alt="history" className="img-fluid" />
                <div className="ms-3">
                  <p> You paid <span className="text-black">₦200,000.00</span> for your <span className="text-black">Health care product</span></p>
                  <span className="timer">20-11-2023 at 10:00 AM</span>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row">
                <img src={history} alt="history" className="img-fluid" />
                <div className="ms-3">
                  <p> You paid <span className="text-black">₦200,000.00</span> for your <span className="text-black">Health care product</span></p>
                  <span className="timer">20-11-2023 at 10:00 AM</span>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row">
                <img src={history} alt="history" className="img-fluid" />
                <div className="ms-3">
                  <p> You paid <span className="text-black">₦200,000.00</span> for your <span className="text-black">Health care product</span></p>
                  <span className="timer">20-11-2023 at 10:00 AM</span>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row">
                <img src={history} alt="history" className="img-fluid" />
                <div className="ms-3">
                  <p> You paid <span className="text-black">₦200,000.00</span> for your <span className="text-black">Health care product</span></p>
                  <span className="timer">20-11-2023 at 10:00 AM</span>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="pay-card px-4 mt-4">
            <h3>FAQs</h3>
            <Accordion className="mt-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>How do i pay for products on onesud?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How do i pay for products on onesud?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>How do i pay for products on onesud?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="addcard" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mb-3 paym">
                <div className="row">
                  <div className="col-sm-8">
                    <h2>Select a payment method</h2>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <form className="col">
                    <div className="mb-3">
                      <label className="form-label">Card Number</label>
                      <input type="number" className="form-control"/>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="form-label">Expiry</label>
                        <input type="number" className="form-control"/>
                      </div>
                      <div className="col">
                        <label className="form-label">CVV</label>
                        <input type="password" className="form-control"/>
                      </div>
                    </div>
                    <div className="mt-5">
                      <Link to="" className="btn btn-next w-100">Add Card</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="paynow" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mb-3 paym">
                <div className="row">
                  <div className="col-sm-8">
                    <h2>Payment</h2>
                  </div>
                </div>
                <hr />
                <div className="row mt-4">
                  <div className="col">
                    <h6>You are about to pay ₦200,000.00 for healthcare product</h6>
                  </div>
                </div>
                <div className="row mt-4">
                  <form className="col">
                    <p className="mb-4">Choose card</p>
                    <div className="form-check mb-4">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                      <label className="form-check-label" for="flexRadioDefault1">
                        <img src={mc} alt="msc" className="img-fluid" />
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                      <label className="form-check-label" for="flexRadioDefault1">
                        <img src={mc} alt="msc" className="img-fluid" />
                      </label>
                    </div>
                    <div className="mt-5">
                      <Link to="" className="btn btn-next w-100">Proceed to Pay</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPayment