import LoginPer from "../components/auths/loginPer"

function PersonalLogin() {
  return (
    <div>
      <LoginPer/>
    </div>
  )
}

export default PersonalLogin