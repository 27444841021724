import { Link } from "react-router-dom"
import pix from "../../assets/img.svg"
import speed from "../../assets/speedometer.svg"
import decision from "../../assets/question.svg"
import userss from "../../assets/networking.svg"
import cs from "../../assets/cs.svg"
import hand from "../../assets/hand.svg"
import tar from "../../assets/tar.svg"
import arrow from "../../assets/arrow.svg"
import leapsail from "../../assets/leapsail.svg"
import babe from "../../assets/babe.svg"
import si from "../../assets/si.svg"
import test from "../../assets/img-testimonial.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import Notification from "../../parts/notification"



function DashboardExplore() {
  return (
    <div className="content">
      <div className="sticky-top container-fluid bg-white dash-bot px-5">
        <div className="row justify-content-end">
          <div className="col-sm-4" style={{marginTop: "16px"}}>
            <h4>Dashboard</h4>
          </div>
          <div className="col-sm-4">
            <Notification/>
          </div>
        </div>
      </div>
      <div className="explore- mt-5">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <h4>EXPLORE ONESCUD</h4>
            <h2 className="my-4">Unlock New Possibilities with the Power of Customized financial solutions</h2>
            <p>Our custom financial solution removes limitations on financial innovation, giving you an open road for your<br/>imagination to become real. So take advantage now and help us create the best solution for your business. We<br/>can't wait to see what you accomplish with our help.</p>
            <Link to="/business/step-two" className="btn btn-primary explore-btn mt-4 mb-5">Start Building Now</Link>
            <img src={pix} alt="props" className="img-fluid" />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-sm-6 text-center">
            <h3>Need help to figure out the right custom solution<br/>for you or your business?</h3>
            <p className="text-black">Take the Quiz below, and we will suggest solutions tailored to you or your business.</p>
          </div>
        </div>
        <div className="row mt-4 mx-5">
          <div className="col-sm-12">
            <div className="quiz">
              <div className="row justify-content-center">
                <div className="col-sm-7">
                  <div className="quizes">
                    <h4>FINANCIAL MANAGEMENT QUIZ</h4>
                    <h4 className="float-end" style={{marginTop: "-12px"}}>1/10</h4>
                    <hr  style={{color:"#323856"}}/>
                    <p className="mt-3">Our custom financial solution removes limitations on financial innovation, giving you an open road for your imagination to become real.<span style={{color: "#7B84B3"}}>So take advantage now and help us create the best solution for your business</span></p>
                    <div className="mt-5">
                      <div className="radio-card mb-3">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Financial Management
                          </label>
                        </div>
                      </div>
                      <div className="radio-card mb-3">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Financial Management
                          </label>
                        </div>
                      </div>
                      <div className="radio-card mb-3">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Financial Management
                          </label>
                        </div>
                      </div>
                      <button className="btn btn-primary explore-btn float-end">Next Question</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mx-5">
          <div className="col-sm-12">
            <div className="card-sol text-center">
              <h5 className="mb-3" style={{fontSize: "24px"}}>Ready to start building your custom solution?</h5>
              <Link to="/business/step-one" className="btn btn-primary explore-btn">Start Building Now</Link>
            </div>
          </div>
        </div>
        <div className="mx-5" style={{marginTop: "100px", marginBottom: "100px"}}>
          <div className="row">
            <div className="col-sm-6">
              <h3 className="mb-2">Why Custom financial solutions?</h3>
              <p>Our custom financial solution removes limitations on financial innovation, giving you an open road for your imagination to become real. So take advantage now and help us create the best solution for your business. We can't wait to see </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-4 mb-3">
              <div className="custom-card">
                <img src={speed} alt="speed" className="img-fluid mb-4" />
                <h6>Increased efficiency</h6>
                <p className="mt-2">Work better and smarter</p>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="custom-card">
                <img src={decision} alt="speed" className="img-fluid mb-4" />
                <h6>Enhanced decision-making</h6>
                <p className="mt-2">Better analysis, better decisions</p>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="custom-card">
                <img src={userss} alt="speed" className="img-fluid mb-4" />
                <h6>User-Friendly</h6>
                <p className="mt-2">Easy-to-use application</p>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-4 mb-3">
              <div className="custom-card">
                <img src={cs} alt="speed" className="img-fluid mb-4" />
                <h6>Customer Support</h6>
                <p className="mt-2">24/7 support from our team</p>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="custom-card">
                <img src={tar} alt="speed" className="img-fluid mb-4" />
                <h6>Improved Accuracy</h6>
                <p className="mt-2">More precision, More authority</p>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="custom-card">
                <img src={hand} alt="speed" className="img-fluid mb-4" />
                <h6>Return On Investment</h6>
                <p className="mt-2">It pays for itself</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-case">
          <div className="row">
            <div className="col text-center">
              <span className="cs1">Case Study 1</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-9">
              <img src={leapsail} alt="leapsail" className="img-fluid mb-5" />
              <h3>“We helped them create a customizable financial solution that improved their financial reporting and analysis”.</h3>
            </div>
          </div>
        </div>
        <div className="bg-case" style={{background: "#F2F2F2"}}>
          <div className="row">
            <div className="col text-center">
              <span className="cs2">Case Study 2</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-9">
              <img src={si} alt="leapsail" className="img-fluid mb-5" />
              <h3 style={{color: "#041004"}}>“Our financial solution helped the company identify and streamline unnecessary expenses, saving 34% of operating expenses”.</h3>
            </div>
          </div>
        </div>
        <div className="bg-case">
          <div className="row justify-content-center">
            <div className="col-sm-5 text-center">
              <h1 style={{color: "#12E4E4", fontSize: "37px"}}>Testimonials</h1>
              <Swiper
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="testimonial mt-5">
                    <img src={test} alt="author-img" className="img-fluid" />
                    <p className="text-white my-5">Using stackivy's onescurd helped our business with tailored financial solutions that have helped us grow and expand the business through insightful financial data  </p>
                    <span className="text-white" style={{fontSize: "11px"}}>CEO Roady</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial mt-5">
                    <img src={test} alt="author-img" className="img-fluid" />
                    <p className="text-white my-5">Using stackivy's onescurd helped our business with tailored financial solutions that have helped us grow and expand the business through insightful financial data  </p>
                    <span className="text-white" style={{fontSize: "11px"}}>CEO Roady</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial mt-5">
                    <img src={test} alt="author-img" className="img-fluid" />
                    <p className="text-white my-5">Using stackivy's onescurd helped our business with tailored financial solutions that have helped us grow and expand the business through insightful financial data  </p>
                    <span className="text-white" style={{fontSize: "11px"}}>CEO Roady</span>
                  </div>
                </SwiperSlide>
              </Swiper>
              
            </div>
          </div>
        </div>
        <div className="bg-case pb-0">
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <img src={babe} alt="" className="img-fluid w-100" />
            </div>
            <div className="col-sm-4">
              <Link to="#"><p className="text-white fs-6 xxx">Get a custom solution now <img src={arrow} alt="" className="img-fluid ms-3"/></p></Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default DashboardExplore