import { Link } from "react-router-dom"
import logo from "../../assets/logo.svg"
import { Icon } from "@iconify/react"

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="row justify-content-center mt-5">
        <div className="col-sm-6">
          <img src={logo} alt="logo" className="img-fluid w-100" />
        </div>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-sm-6">
          <Link to="/dashboard"><Icon icon="solar:home-2-outline" className="me-3 nme-1 fs-4" />Home</Link>
          <Link to="/request" ><Icon icon="lucide:git-pull-request" className="me-3 nme-1 fs-4" />Request</Link>
          <Link to="/support"><Icon icon="icons8:support" className="me-3 nme-1 fs-4" />Support</Link>
          <Link to="/reports"><Icon icon="iconoir:reports" className="me-3 nme-1 fs-4" />Reports</Link>
          <Link to="/integration"><Icon icon="material-symbols:integration-instructions-outline-rounded" className="me-3 nme-1 fs-4" />Integration</Link>
          <Link to="/payment"><Icon icon="solar:wallet-outline" className="me-3 nme-1 fs-4" />Payment</Link>
          <Link to="/explore"><Icon icon="material-symbols:explore-outline" className="me-3 nme-1 fs-4" />Explore</Link>
          <div className="topping">
            <a href="/logout"><Icon icon="ion:log-in-outline" className="me-3 nme-1 fs-4" />Log Out</a>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Sidebar