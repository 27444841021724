import SignIn from "../components/auths/signin"

function Login() {
  return (
    <div>
      <SignIn/>
    </div>
  )
}

export default Login