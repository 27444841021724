import LoginGovt from "../components/auths/loginGovt"

function GovtLogin() {
  return (
    <div>
      <LoginGovt/>
    </div>
  )
}

export default GovtLogin